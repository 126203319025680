<div
  class="flex flex-col gap-4 border border-gray-200 shadow-sm bg-white rounded-md w-full px-6 py-4"
>
  <div
    class="flex flex-row justify-between items-center gap-6 border-b border-dashed border-gray-200 py-3"
  >
    <div class="flex flex-row gap-2 items-center">
      <app-custom-image
        [imgId]="order?.shops?.[0]?.avatar?.id"
        [alt]="order?.shops?.[0]?.name ?? 'avatar'"
        styleClass="h-[30px] w-[30px] rounded-full object-cover h-full"
      ></app-custom-image>

      <a
        [href]="order?.shops?.[0]?.shop_id | redirectUrl"
        target="_blank"
        class="line-clamp-1 text-lg font-semibold max-w-[300px] text-gray-900 hover:text-blue-600 hover:underline cursor-pointer"
        >{{ order?.shops?.[0]?.name ?? "--" }}</a
      >

      <div *ngIf="order?.shops?.[0]?.products?.[0]?.livestream_campaign_id !== null" class="text-orange-dark-600 text-sm">
        · {{ orderPrefix + "livestream-order" | translate }}
      </div>
    </div>

    <div class="flex flexc-row gap-1 items-center">
      <span class="truncate text-base font-normal text-gray-900">{{
        order?.shops?.[0]?.code
      }}</span>

      <i
        *ngIf="order?.shops?.[0]?.code"
        class="{{ SoctripIcons.COPY_02 }} text-xl text-blue-600 cursor-pointer"
        ngxClipboard
        [cbContent]="order?.shops?.[0]?.code"
        (cbOnSuccess)="onCopySuccess()"
      ></i>
    </div>
  </div>

  <span class="text-lg font-semibold text-gray-900">{{
    orderPrefix + "product-list" | translate | sentenceCase
  }}</span>

  <div *ngFor="let shop of order.shops" class="grid gap-4">
    <ng-container *ngIf="shop?.products?.length">
      <div
        *ngFor="let product of shop?.products"
        class="flex gap-4 items-start justify-between"
      >
        <div class="flex flex-row gap-4 items-start">
          <img
            class="inline-block min-w-[5rem] w-20 h-20 object-cover object-center border border-gray-200 rounded-lg"
            [src]="getImgWebp(product?.avatar?.id)"
            onerror="this.src='assets/imgs/default/image.webp'"
          />
          <div class="w-full flex flex-col gap-1">
            <div class="text-sm text-gray-700 font-semibold text-ellipsis">
              {{ product?.name }}
            </div>
            <div
              *ngIf="product?.prices1 && product?.variation1"
              class="flex gap-1 text-xs font-medium"
            >
              <span class="text-gray-500">{{ product.prices1 }}:</span>
              <span class="text-gray-700">{{ product.variation1 }}</span>
            </div>
            <div
              *ngIf="product?.prices2 && product?.variation2"
              class="flex gap-1 text-xs font-medium"
            >
              <span class="text-gray-500">{{ product.prices2 }}:</span>
              <span class="text-gray-700">{{ product.variation2 }}</span>
            </div>
            <div class="flex gap-1 text-xs font-medium">
              <span class="text-gray-500">
                {{ "common.quantity" | translate | sentenceCase }}:
              </span>
              <span class="text-gray-700">
                {{ product?.quantity || "--" }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex items-end min-w-max">
          <span class="flex gap-2 items-center">
            <span
              *ngIf="
                product?.original_price &&
                product?.original_price !== product?.current_price
              "
              class="text-sm text-gray-500 line-through"
            >
              {{ product?.original_price | currency: getCurrencyCode() }}
            </span>
            <span class="font-semibold text-gray-700">
              {{ product?.current_price | currency: getCurrencyCode() }}
            </span>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
